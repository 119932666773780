import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { size, cardTitle, width } from './BlogCard.module.scss';
import AnimatedCardWrapper from '../../utils/AnimatedCardWrapper';

const BlogCard = ({ image, title, excerpt, date, link }) => (
  <Link to={link} aria-label="Link to blog post" className={width}>
    <AnimatedCardWrapper whenInView className={`card mb-5 ${size}`}>
      <div className="card-image">
        <figure className="image">
          <GatsbyImage image={getImage(image)} className={width} imgClassName={width} alt="" />
        </figure>
      </div>
      <div className="card-content">
        <p className={`title is-4 ${cardTitle}`} title={title}>
          {title}
        </p>
        <p className="subtitle is-5 has-text-info">{date}</p>
        <div className="content">{excerpt}</div>
      </div>
    </AnimatedCardWrapper>
  </Link>
);

BlogCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default BlogCard;
