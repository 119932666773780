import React from 'react';
import PropTypes from 'prop-types';

import { item } from './Grid.module.scss';

const Grid = ({ children, cols }) => {
  const rows = [];

  for (let i = 0; i < children.length; i += cols) {
    rows.push(
      <div key={i} className="tile is-parent">
        {[...Array(cols).keys()].map(
          (idx) =>
            children[i + idx] && (
              <div className={`tile is-child ${item}`} key={idx}>
                {children[i + idx]}
              </div>
            )
        )}
      </div>
    );
  }

  return <div className="tile is-ancestor is-vertical mt-6">{rows}</div>;
};

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  cols: PropTypes.number.isRequired
};

export default Grid;
