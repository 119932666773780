import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import '../main.scss';
import Wrapper from '../components/Wrapper/Wrapper';
import BlogPageTemplate from '../components/Templates/Blog/BlogPageTemplate';
import SEO from '../components/SEO';

const BlogPage = ({ data, pageContext }) => (
  <Wrapper>
    <SEO />
    <BlogPageTemplate blogPosts={data.allMarkdownRemark.edges} pageContext={pageContext} />
  </Wrapper>
);

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    navPrefix: PropTypes.string.isRequired
  }).isRequired
};

export default BlogPage;

export const query = graphql`
  query BlogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "BlogPost" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 400, height: 300)
              }
            }
          }
        }
      }
    }
  }
`;
