import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Pagination = ({ pageContext }) => {
  const { numPages, currentPage, navPrefix } = pageContext;

  const prevPage = currentPage - 1 < 1 ? null : currentPage - 1;
  const nextPage = currentPage + 1 > numPages ? null : currentPage + 1;

  return (
    <nav className="pagination is-centered my-4 mx-3" role="navigation" aria-label="pagination">
      <Link className={`pagination-previous ${!prevPage && 'disabled'}`} to={`${navPrefix}/${prevPage}`}>
        Previous
      </Link>
      <Link className={`pagination-next ${!nextPage && 'disabled'}`} to={`${navPrefix}/${nextPage}`}>
        Next page
      </Link>
      <ul className="pagination-list">
        {numPages >= 7 && currentPage - 1 >= 2 && (
          <li>
            <Link className="pagination-link" aria-label="Goto page 1" to={navPrefix}>
              1
            </Link>
          </li>
        )}
        {numPages >= 7 && currentPage - 1 >= 3 && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}
        {prevPage && (
          <li>
            <Link className="pagination-link" aria-label={`Goto page ${prevPage}`} to={`${navPrefix}/${prevPage}`}>
              {prevPage}
            </Link>
          </li>
        )}
        <li>
          <button
            type="button"
            className="pagination-link is-current"
            aria-label={`Page ${currentPage}`}
            aria-current="page"
          >
            {currentPage}
          </button>
        </li>
        {nextPage && (
          <li>
            <Link className="pagination-link" aria-label={`Goto page ${nextPage}`} to={`${navPrefix}/${nextPage}`}>
              {nextPage}
            </Link>
          </li>
        )}
        {numPages >= 7 && numPages - currentPage >= 3 && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}
        {numPages >= 7 && numPages - currentPage >= 2 && (
          <li>
            <Link className="pagination-link" aria-label={`Goto page ${numPages}`} to={`${navPrefix}/${numPages}`}>
              {numPages}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    navPrefix: PropTypes.string.isRequired
  }).isRequired
};

export default Pagination;
