import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedCardWrapper = ({ children, whenInView, ...attrs }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const variants = {
    hidden: { opacity: 0, scale: 0.6 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
  };

  if (whenInView) {
    return (
      <motion.article
        initial="hidden"
        animate={inView && 'visible'}
        variants={variants}
        whileHover={{ scale: 1.05, transition: { ease: 'easeIn' } }}
        whileTap={{ scale: 0.95, transition: { ease: 'easeIn' } }}
        ref={ref}
        {...attrs}
      >
        {children}
      </motion.article>
    );
  }
  return (
    <motion.article
      initial="hidden"
      animate="visible"
      variants={variants}
      whileHover={{ scale: 1.05, transition: { ease: 'easeIn' } }}
      whileTap={{ scale: 0.95, transition: { ease: 'easeIn' } }}
      {...attrs}
    >
      {children}
    </motion.article>
  );
};

AnimatedCardWrapper.defaultProps = {
  whenInView: false
};

AnimatedCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  whenInView: PropTypes.bool
};

export default AnimatedCardWrapper;
