import React from 'react';
import PropTypes from 'prop-types';

import { section, banner, titleId } from './BlogPageTemplate.module.scss';
import Grid from '../../Grid/Grid';
import BlogCard from '../../Cards/Blog/BlogCard';
import Pagination from '../../Pagination';
import bannerImg from '../../../assets/svg/working-out.svg';

const BlogPageTemplate = ({ blogPosts, pageContext }) => (
  <main id="container" className="container">
    <section className={section}>
      <figure className="image">
        <img className={banner} src={bannerImg} alt="Illustration of a man and woman working out." />
      </figure>
      <h1 id={titleId} className="title has-text-centered is-bold has-text-white">
        Blog
      </h1>
    </section>
    <section>
      <Grid cols={2}>
        {blogPosts.map(
          ({
            node: {
              id,
              fields: { slug },
              excerpt,
              frontmatter: { featuredImage, title, date }
            }
          }) => (
            <BlogCard key={id} image={featuredImage} title={title} excerpt={excerpt} date={date} link={slug} />
          )
        )}
      </Grid>
      <Pagination pageContext={pageContext} />
    </section>
  </main>
);

BlogPageTemplate.propTypes = {
  blogPosts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  pageContext: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    navPrefix: PropTypes.string.isRequired
  }).isRequired
};

export default BlogPageTemplate;
